import React from "react";

import clsx from "clsx";

import ArrowsBadgeIcon from "@js/component/icon/badge/ArrowsBadgeIcon";
import Box from "@js/component/Box";
import LinkButton from "@js/component/LinkButton";
import LongArrowRightIcon from "@js/component/icon/LongArrowRightIcon";

import { BoxProps } from "@js/component/Box";

import * as classNames from "@css/component/BoxCompare.module.scss";
import Heading from "@js/component/Heading";
import CompareIcon from "@js/component/icon/CompareIcon";
import {GatsbyImage, IGatsbyImageData} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";

/**
 * @type FileData
 */
type FileData = {
    file: {
        childImageSharp: {
            gatsbyImageData: IGatsbyImageData
        };
    };
};

/**
 * @type BoxCompareProps
 */
export type BoxCompareProps = Omit<BoxProps, "children">;

/**
 * @const BoxCompare
 */
const BoxCompare = (props: BoxProps) => {
    const {
        className,
        ...restProps
    } = props;

    const data: FileData = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "compare.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                }
            }
        }
    `);

    return (
        <Box { ...restProps } className={ clsx(classNames.boxCompare, className) } element="section">
            <div className={ classNames.text }>
                <Heading className={ classNames.heading } element="h2" variant="medium">
                    Start jouw <strong>vergelijking</strong>
                </Heading>
                <p className={ classNames.paragraph }>
                    Van gecertificeerde adviseurs op prijs, levertijd en beoordeling.
                </p>
                <GatsbyImage
                    alt="compare-image"
                    className={ classNames.image }
                    image={ data.file.childImageSharp.gatsbyImageData }
                />
            </div>
            <div className={ classNames.button }>
                <LinkButton className={ classNames.linkButton } to="/vergelijk">
                    <CompareIcon className={ classNames.icon } />
                    <span>Vergelijk adviseurs</span>
                </LinkButton>
            </div>
        </Box>
    );
};

export default BoxCompare;
