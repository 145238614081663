import React, {DetailedHTMLProps, HTMLAttributes} from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import Main from "@js/component/Main";
import {Helmet} from "react-helmet";
import FeaturedImage from "@js/component/FeaturedImage";
import * as classNames from "@css/component/template/Employee.module.scss";
import Heading from "@js/component/Heading";
import Box from "@js/component/Box";
import BoxCompare from "@js/component/BoxCompare";
import {graphql, Link, useStaticQuery} from "gatsby";
import clsx from "clsx";
import ArrowLeftIcon from "@js/component/icon/ArrowLeft";
import BreadCrumbs from "@js/component/BreadCrumbs";

/**
 * @type EmployeeProps
 */
type EmployeeProps = {
    pageContext: {
        content: string;
        details: {
            emailaddress: string;
            phonenumber: string;
        };
        featuredImage: {
            node: {
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                    publicURL: string;
                };
                altText: string;
                height: number;
                mimeType: string;
                sourceUrl: string;
                width: number;
            };
        };
        id: number;
        slug: string;
        seo: {
            breadcrumbs: {
                text: string;
                url: string;
            }[];
            metaDesc: string;
            metaKeywords: string;
            metaRobotsNofollow: string;
            metaRobotsNoindex: string;
            opengraphDescription: string;
            opengraphModifiedTime: string;
            opengraphPublishedTime: string;
            opengraphPublisher: string;
            opengraphSiteName: string;
            opengraphTitle: string;
            opengraphType: string;
            opengraphUrl: string;
            title: string;
            twitterDescription: string;
            twitterTitle: string;
            schema: {
                raw: string;
            };
        };
        taxonomy: {
            name: string;
        }[];
        title: string;
        uri: string;
    };
};

/**
 * @type AboutUsPageProps
 */
type AboutUsPageProps = {
    wordpressPage: {
        title: string
        featuredImage: {
            node: {
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                    publicURL: string;
                };
                altText: string;
                height: number;
                mimeType: string;
                sourceUrl: string;
                width: number;
            }
        },
    };
};

/**
 * @type EmployeeDataProps
 */
type EmployeeDataProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children"> & EmployeeProps;

const Employee = (props: EmployeeDataProps) => {
    const {
        pageContext
    } = props;

    const data: AboutUsPageProps = useStaticQuery(graphql`
        query {
            wordpressPage(isAboutUsPage: { eq: true }) {
                title
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 1920, quality: 100)
                            }
                            publicURL
                        }
                        altText
                        height
                        mimeType
                        sourceUrl
                        width
                    }
                }
            }            
        }
    `);

    const OpenGraphImage = process.env.SITE_URL + pageContext.featuredImage?.node.localFile.publicURL;

    return (
        <Main>
            <Helmet>
                <title>{ pageContext.title }</title>

                { pageContext.seo.metaKeywords && <meta content={ pageContext.seo.metaKeywords } name="keywords" /> }
                { pageContext.seo.metaDesc && <meta content={ pageContext.seo.metaDesc } name="description" /> }

                <meta content={ `${ pageContext.seo.metaRobotsNofollow }, ${ pageContext.seo.metaRobotsNoindex }` } name="robots" />

                { pageContext.seo.opengraphDescription && <meta content={ pageContext.seo.opengraphDescription } property="og:description" /> }
                { pageContext.seo.opengraphModifiedTime && <meta content={ pageContext.seo.opengraphModifiedTime } property="article:modified_time" /> }
                { pageContext.seo.opengraphPublishedTime && <meta content={ pageContext.seo.opengraphPublishedTime } property="article:published_time" /> }
                { pageContext.seo.opengraphSiteName && <meta content={ pageContext.seo.opengraphSiteName } property="og:site_name" /> }
                { pageContext.seo.opengraphTitle && <meta content={ pageContext.seo.opengraphTitle } property="og:title" /> }
                { pageContext.seo.opengraphType && <meta content={ pageContext.seo.opengraphType } property="og:type" /> }
                { pageContext.seo.opengraphUrl && <meta content={ pageContext.seo.opengraphUrl } property="og:url" /> }

                <meta property="og:image" content={ OpenGraphImage } />
                <meta property="og:image:type" content={ pageContext.featuredImage?.node.mimeType } />
                <meta property="og:image:width" content={ pageContext.featuredImage?.node.width.toString() } />
                <meta property="og:image:height" content={ pageContext.featuredImage?.node.height.toString() } />
                <meta property="og:image:alt" content={ pageContext.featuredImage?.node.altText } />

                { pageContext.seo.twitterDescription && <meta content={ pageContext.seo.twitterDescription } name="twitter:description" /> }
                { pageContext.seo.twitterTitle && <meta content={ pageContext.seo.twitterTitle } name="twitter:title" /> }

                <script type="application/ld+json" className="yoast-schema-graph">
                    { pageContext.seo.schema.raw }
                </script>
            </Helmet>
            <FeaturedImage
                gatsbyImageData={ data.wordpressPage.featuredImage.node.localFile.childImageSharp.gatsbyImageData }
            />
            <div className={ classNames.container }>
                <BreadCrumbs
                    breadCrumbs={ pageContext.seo.breadcrumbs.slice(1) }
                    parents="over-ons"
                />
                <article className={ classNames.article }>
                    <Link
                        className={ classNames.link }
                        to="/over-ons"
                    >
                        <ArrowLeftIcon className={ classNames.icon } />
                        <Heading element="h2" variant="medium">
                            { data.wordpressPage.title }
                        </Heading>
                    </Link>
                    <div className={ classNames.blocks }>
                        <div className={ classNames.blockOne }>
                            <Box className={ clsx(classNames.box, classNames.employee) }>
                                <FeaturedImage
                                    gatsbyImageData={ pageContext.featuredImage.node.localFile.childImageSharp.gatsbyImageData }
                                    variant="employeeSingle"
                                />
                                <div>
                                    <Heading element="h1" variant="medium">
                                        { pageContext.title }
                                    </Heading>
                                    { pageContext.taxonomy.length > 0 &&
                                    <div className={ classNames.employeeDetails }>
                                        <label>Functie</label>
                                        <span>{ pageContext.taxonomy.map(job => job.name).join(', ') }</span>
                                    </div>
                                    }
                                    { pageContext.details.emailaddress &&
                                    <div className={ classNames.employeeDetails }>
                                        <label>E-mailadres</label>
                                        <span>
                                            <a href={`mailto:${ pageContext.details.emailaddress }`}>
                                                { pageContext.details.emailaddress }
                                            </a>
                                        </span>
                                    </div>
                                    }
                                    { pageContext.details.phonenumber &&
                                    <div className={ classNames.employeeDetails }>
                                        <label>Telefoon</label>
                                        <span>
                                            <a href={`tel:${ pageContext.details.phonenumber.replace('-', '') }`}>
                                                { pageContext.details.phonenumber }
                                            </a>
                                        </span>
                                    </div>
                                    }
                                    <div className={ classNames.infoBlock }>
                                        <Heading element="h2" variant="medium">
                                            Informatie
                                        </Heading>
                                        <div className={ classNames.infoContent } dangerouslySetInnerHTML={{ __html: pageContext.content }}></div>
                                    </div>
                                </div>
                            </Box>
                        </div>
                        <div className={ classNames.blockTwo }>
                            <BoxCompare />
                        </div>
                    </div>
                </article>
            </div>
        </Main>
    );
}

export default Employee;