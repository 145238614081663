import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import * as classNames from "@css/component/Main.module.scss";

/**
 * @type MainProps
 */
export type MainProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

/**
 * @const Main
 */
const Main = (props: MainProps) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <main { ...restProps } className={ clsx(classNames.main, className) } />
    );
};

export default Main;
