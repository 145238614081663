import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import * as classNames from "@css/component/Box.module.scss";

/**
 * @type BoxProps
 */
export type BoxProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    element?: "article" | "div" | "section";
};

/**
 * @const Box
 */
const Box = (props: BoxProps) => {
    const {
        className,
        element,
        ...restProps
    } = props;

    const Element = element || "div";

    return (
        <Element { ...restProps } className={ clsx(classNames.box, className) } />
    );
};

export default Box;
