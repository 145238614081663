// extracted by mini-css-extract-plugin
export var article = "Employee-module--article---yx89";
export var blockOne = "Employee-module--blockOne--DNG9n";
export var blockTwo = "Employee-module--blockTwo--4b6O4";
export var blocks = "Employee-module--blocks--RLRsg";
export var box = "Employee-module--box--E2IXy";
export var container = "Employee-module--container--hHIGW";
export var employee = "Employee-module--employee--4y7sa";
export var employeeDetails = "Employee-module--employeeDetails--ucl60";
export var icon = "Employee-module--icon--GcORd";
export var infoBlock = "Employee-module--infoBlock--jH1Cz";
export var infoContent = "Employee-module--infoContent--ipy1W";
export var link = "Employee-module--link--iWw36";